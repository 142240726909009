@use '../breakpoints';

.header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .header-label {
        display: flex;
        align-items: center;
    }
}

.btn-close-menu {
    font-size: 1.7em;
    line-height: 1;
    background: none;
    border: none;
    cursor: pointer;
}

.legal-note {
    max-height: 70dvh ;
    overflow: auto;
    .info-legal {
        padding-bottom: 10px;
    }
        h2 {
            color: var(--primary);            
        }

}
.legal-note::-webkit-scrollbar {
    width: 8px;
    border-radius: .5em;
    background-color: var(--primary-shadow);
    @media screen and (width < breakpoints.$desktop) {
        display: none; /* Hide scrollbar for Chrome, Safari and Opera */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

.legal-note:-webkit-scrollbar-thumb {
    border-radius: .5em;
    background-color: var(--primary);
}


.personal-data {
    display: flex;
    max-height: 70dvh ;
    flex-direction: column;
    overflow: auto;
    row-gap: 10px;

    @media screen and (width < breakpoints.$desktop) {
        max-height: 100dvh;
    }

    h2 {
        color: var(--primary)
    }

   .personal-data-section {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    .table-cookies {
        display: table;
        border-radius: .5em;
        border-collapse: collapse;

        tr {
            border-bottom: 1px solid;
        }
        
        td {
            display: table-cell;
            padding: 10px 5px;
        }
        th {
            padding: 10px 5px;
            font-weight: 700;
            text-align: left;
        }
    }
   }

   .cookies-def {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    .cookies-def-headers {
        color: var(--primary);
        font-style: italic;
        font-weight: 700;
        font-size: 1.2em;
    }
   }
   .cookie-explain {
    font-weight: bold;
   }
   .cookies-technique-headers {
    color: var(--primary);
    font-weight: 700;
    text-decoration: underline;
   }
}

.personal-data::-webkit-scrollbar {
    width: 8px;
    border-radius: .5em;
    background-color: var(--primary-shadow);
    @media screen and (width < breakpoints.$desktop) {
        display: none; /* Hide scrollbar for Chrome, Safari and Opera */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

.personal-data::-webkit-scrollbar-thumb {
    border-radius: .5em;
    background-color: var(--primary);
}

